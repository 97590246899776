import { createStore, combineReducers, applyMiddleware } from 'redux'
import { thunk } from 'redux-thunk'
import authReducer from './authReducer'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// Initial state for the sidebar reducer
const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: false,
}

// Sidebar reducer
const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

// Persist configuration
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
}

// Combine reducers
const rootReducer = combineReducers({
  auth: authReducer,
  sidebar: changeState,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer, applyMiddleware(thunk))
export default store
export const persistor = persistStore(store)
